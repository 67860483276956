<template>
  <b-card>

    <header-table title="الإجراءات" @create="onClickedAdd" />


    <div class="border pt-1 px-1 rounded-lg shadow-sm">
      <b-row>
        <b-col cols="5">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
      </b-row>
    </div>

    <div>
      <dropdown-data ref="refProcedureDropdown" ep="/procedures" :filter.sync="filter" collapseType="">
        <template #header="item">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h6>{{ item.title }}</h6>
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item @click="onUpdateClicked(item)" v-can="{ slug: $route.meta.slug, action: 'U' }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">تعديل</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDelete(item)" v-can="{ slug: $route.meta.slug, action: 'D' }">
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">حذف</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #detals="item">
          <content-procedure :item="item" />
        </template>
      </dropdown-data>
    </div>
    <form-modal ref="estateModalProcedures" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>
<script>
import { BCard, BButton, BRow, BCol, BPagination, BDropdown, BDropdownItem } from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import DropdownData from "@/components/dropdown-data/index.vue";
import ContentProcedure from "../../component/ContentProcedure.vue";
import { mapActions, mapGetters } from "vuex";
import HeaderTable from "@/components/data-table/components/header-table.vue";
export default {
  components: {
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    DropdownData,
    ContentProcedure,
    BDropdown,
    BDropdownItem,
    HeaderTable
},
  directives: {
    Ripple,
  },
  data: () => {
    return {
      filter: "",
      activModel: false,
      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            label: "اسم الإجراء",
            rules: "required",
          },
        },
        cost: {
          component: "FormMask",
          key: "cost",
          attrs: {
            options: "number",
            rules: "required",
            label: "التكلفة",
          },
        },
        expected_duration: {
          component: "FormInput",
          key: "expected_duration",
          attrs: {
            label: "المدة المتوقعة",
            rules: "required",
          },
        },
        content: {
          component: "FormQuill",
          key: "content",
          attrs: {
            label: "المحتوى",
            rules: "required",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("manageApp/procedures", [
      "update",
      "create",
      "delete",
    ]),
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الإجراء", {
          title: "تأكيد حذف الإجراء",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({
              id: item.id,
              page: this.currentPage,
              per_page: this.per_page,
              filter: this.filter,
              type_id: this.type_id,
            }).then(() => {
              this.$refs.refProcedureDropdown.refreshOptions({});
            });
        });
    },
    onUpdateClicked(item) {
      var toUpdate = {
        id: item.id,
        title: item.title,
        content: item.content,
        cost: item.cost,
        expected_duration: item.expected_duration,
      };
      this.$refs.estateModalProcedures.init(toUpdate);
      this.activModel = true;
    },
    onClickedAdd() {
      this.$refs.estateModalProcedures.init({});
      this.activModel = true;
    },
    onModalConfirmed(form) {
      if (form.id) {
        this.update({ id: form.id, fd: {...form,cost: form.cost.replace(/\D/g, ""), } }).then(() => {
          this.activModel = false;
          this.$refs.refProcedureDropdown.refreshOptions({});
        });
      } else
        this.create({ fd: { ...form, cost: form.cost.replace(/\D/g, "") } }).then(() => {
          this.activModel = false;
          this.$refs.refProcedureDropdown.refreshOptions({});
        });
    },
  },
  computed: {
    ...mapGetters("manageApp/procedures", ["loading"]),
  },
};
</script>